import React, { useState } from "react";
import { Link } from "gatsby";

import { Tabs, TabList, Tab, TabPanels, TabPanel } from "@reach/tabs";
import { Dialog } from "@reach/dialog";
import "@reach/dialog/styles.css";
import VisuallyHidden from "@reach/visually-hidden";

import Layout from "../components/layout"
import SEO from "../components/seo"
import HistoryGrid from "../components/historygrid/historygrid";
import ArchiveGrid from "../components/archivegrid/archivegrid";

import {filter} from 'lodash';


const Stadtgeschichte = (props) => {

    const language_id = 1;

    // on tabs changed -> invalidate size for map
    // let onTabsChanged = (activeTabIndex) => {
    //     setActiveTabIndex(activeTabIndex);
    // }

    const translations = {
        town_history : {
            1: "City history",
            2: "Stadtgeschichte"
        },
        town_history_description : {
            1: "Tulln and its population were shaped by over 2,000 years - with many ups and downs, crises and heydays. Divided into different subject areas, you will find insights into the eventful history of Tulln. In the “Archive of Time”, contemporary witnesses tell about the decisive experiences of the past century from their own personal point of view.",
            2: "Über 2.000 Jahre haben die Stadt Tulln und ihre Bevölkerung geformt – mit vielen Höhen und Tiefen, Krisen und Glanzzeiten. Gegliedert in verschiedene Themenbereiche finden Sie hier Einblicke in die bewegte Geschichte Tullns. Im „Archiv der Zeit“ erzählen Zeitzeugen über einschneidende Erlebnisse des vergangenen Jahrhunderts aus ihrer ganz persönlichen Sicht."
        },
        town_history_tab : {
            1: "The history of the city of Tulln",
            2: "Die Geschichte der Stadt Tulln"
        },
        town_archive_tab : {
            1: "The archive of time",
            2: "Das Archiv der Zeit"
        },
        bibliography : {
            1: "Bibliography",
            2: "Bibliographie"
        }
    }

    return (
        <>
            <Layout pathContext={props.pathContext}>
                <SEO title="Town History" />

                

                <section className="town_history_section">
                    <div className="town_history_wrapper main_content_wrapper">

                        <h1>{translations.town_history[language_id]}</h1>
                        <p className="town_history_description">{translations.town_history_description[language_id]}</p>

                        <Tabs>
                            <TabList>
                                <Tab>{translations.town_history_tab[language_id]}</Tab>
                                <Tab>{translations.town_archive_tab[language_id]}</Tab>
                            </TabList>

                            <TabPanels>
                                <TabPanel>
                                    <HistoryGrid lang_id={language_id} />
                                    <button className="bibliography_link link_btn">
                                        <Link to="/en/bibliography">{translations.bibliography[language_id]}</Link>
                                    </button>
                                </TabPanel>

                                <TabPanel>
                                    <ArchiveGrid lang_id={language_id} />
                                </TabPanel>
                            </TabPanels>
                        </Tabs>
                    </div>


                    <div className="main_content_leaf_deco_1"></div>
                    <div className="main_content_leaf_deco_2"></div>

                    <div className="dust_deco_1"></div>
                    <div className="dust_deco_2"></div>
                </section>
                
            </Layout>
        </>
    );
};

export default Stadtgeschichte;